<template>
  <div style="background: #f6f6f6; height: 100%;">
    <van-nav-bar title="退款详情" left-arrow fixed placeholder @click-left="onClickLeft" />

    <div class="setps container">
      <van-steps direction="vertical" :active="0" active-color="#3E85FF">
        <van-step>
          <h3 v-show="data.status == '1' || data.status == '6'">等待商家处理 {{ residueTime }}</h3>
          <h3 v-show="data.status == '11' || data.status == '61'">退款成功 {{ data.refundTime }}</h3>
          <p v-show="data.status == '11' || data.status == '61'" class="steps-shuoming">实退金额 ￥{{ data.refundAmount }}</p>
          <p v-show="data.status == '11' || data.status == '61'" class="steps-shuoming">已退至您{{ data.paymentType == '1' ? '微信' : data.paymentType == '5' ? '' : '支付宝' }}的账户，可前往{{
    data.paymentType == '1' ? '"微信-账户"' : data.paymentType == '5' ? '账户' : '支付宝-账户' }}查看</p>
          <h3 v-show="data.status == '5' || data.status == '63'">已撤消</h3>
          <h3 v-show="data.status == '11' || data.status == '61'">商家同意退款</h3>
          <h3 v-show="data.status == '12' || data.status == '62'">商家拒绝退款</h3>
          <p v-show="data.status == '12' || data.status == '62'" class="refuseReason">拒绝理由：{{ data.refuseRefundReson }}
          </p>
        </van-step>
        <van-step>
          <div class="steps-item" v-show="data.status == '1' || data.status == '5' || data.status == '6'">
            <span class="steps-item-one">提交申请</span> &nbsp;
            <span class="steps-item-tow">{{ data.createTime }}</span>
          </div>
          <p v-show="data.status == '1' || data.status == '5' || data.status == '6' || data.status == '12' || data.status == '62'"
            class="steps-shuoming">您已成功发起退款申请，请耐心等待商家处理，商家同意或者超时未处理，系统将退款给您，如果商家拒绝，您可以修改后再次发起，商家重新处理</p>
          <div class="steps-item" v-show="data.status == '11' || data.status == '61'">商家同意退款</div>
        </van-step>
      </van-steps>
    </div>

    <div class="info container">
      <div class="info-shangpin">
        <span>
          <van-image width="65" height="65" fit="cover" :src="data.picUrl" />
        </span>
        <div class="info-shangpin-right">
          <div class="info-shangpin-right-name shangpin_name">
            <span class="info-shangpin-right-name-ellipsis">{{ data.spuName }}</span>
            <!-- <span>￥{{ data.salesPrice }}</span> -->
            <span>￥{{ data.price }}</span>
          </div>
          <div class="info-shangpin-right-name shangpin_guige">
            <span>规格：{{ data.specification }}</span>
            <span>x{{ data.quantity }}</span>
          </div>
        </div>
      </div>

      <ul class="list">
        <li>
          <span>退款金额</span>
          <span class="hongse">￥{{ data.refundAmount }}</span>
        </li>
        <li>
          <span>退款类型</span>
          <span>仅退款</span>
        </li>
        <li>
          <span>退款原因</span>
          <span>{{ data.refundReson }}</span>
        </li>
        <li>
          <span>申请时间</span>
          <span>{{ data.createTime }}</span>
        </li>
        <li>
          <span>订单编号</span>
          <span>
            <span>{{ data.orderId }}</span>
            <span style="text-decoration: underline; margin-left: 0.1351rem;" class="hongse span"
              @click="clipBoard(data.orderId)">复制</span>
          </span>
        </li>
      </ul>

      <div class="info-btn">
        <div class="info-btn-container" @click="contact" v-if="icon">
          <img class="info-btn-container-img" src="./img/chat.png" alt="">
          <span>商家客服</span>
        </div>
        <div class="info-btn-kong" v-else></div>
        <div class="info-btn-container" @click="callPhone">
          <a ref="tels" class="a_html">
            <img class="info-btn-container-img" src="./img/phone.png" alt="">
            <span>官方客服</span>
          </a>
        </div>
      </div>
    </div>

    <div class="shexiao" v-show="data.status == '12' || data.status == '1' || data.status == '6' || data.status == '62'">
      <!-- <span></span> -->
      <van-tabbar :fixed="isTop">
        <span v-show="data.status == '12' || data.status == '62'">
          <van-button round size="small" @click="refundAgain">重新申请</van-button>
        </span>
        <span v-show="data.status == '1' || data.status == '6'">
          <van-button round size="small" @click="cancalRefundDetail">撤销申请</van-button>
        </span>
      </van-tabbar>
    </div>

    <van-popup v-model="show" round position="bottom" :style="{ height: '40%' }">
      <div class="box">
        <p class="box-title">退款原因</p>
        <ul>
          <li class="box-item" v-for="(x, i) in refundReason" :key="x" @click="selectReason(i)">
            <span v-show="index !== i">
              <van-icon name="checked" size="17" color="#999" />
            </span>
            <span v-show="index === i">
              <van-icon name="checked" size="17" color="#FB2B53" />
            </span>
            <span style="margin-left: 0.1351rem;">{{ x }}</span>
          </li>
        </ul>
        <span class="box-btn">
          <van-button color="#FB2B53" size="large" round @click="chexiaoshenqing">提交</van-button>
        </span>
      </div>
    </van-popup>

    <loading :loading="loading" @closeLoading="closeLoading"/>
  </div>
</template>

<script>
import ClipBoard from 'clipboard'
import { Toast, Dialog } from 'vant'
import { refundDetail, cancalRefundDetail, chexiaoshenqing } from '@/api/flMall'
import { giveUpApply } from '@/api/goodsService/index'
import Loading from './actionSheet/Loading'
import { contact } from "@/common"
export default {
  components: {
    Loading
  },

  data() {
    return {
      isTop: true,
      data: {},
      residueTime: '',
      loading: false,
      show: false,
      refundReason: ['商家未按约定时间发货', '与商家协商一致退款', '不喜欢/不想要', '收货地址拍错', '其它'],
      index: 0,
      icon: '',
      timer1: null,
      timeNum: null
    }
  },

  created() {
    this.$store.commit('setSession', this.$route.query.session)
  },

  mounted() {
    let screenWidth = document.documentElement.getBoundingClientRect().width
    document.documentElement.style.fontSize = (screenWidth / 10) + 'px'
    this.getPage()
  },
  destroyed() {
    clearInterval(this.timer1)
  },
  activated() {
    this.getPage()
    this.getPhoneCode()
  },
  methods: {
    clipBoard(orderNo) {
      const clipBoard = new ClipBoard('.span', {
        text: () => {
          return orderNo
        }
      })
      clipBoard.on('success', () => {
        Toast('复制成功')
        clipBoard.destroy()
      })
      clipBoard.on('error', () => {
        Toast('复制失败')
      })
    },
    //重新申请
    refundAgain() {
      if (this.data.status == '12') {
        this.show = true
      } else if (this.data.status == '62') {
        // console.log(this.data, '准备携带的数据');
        this.$router.push({
          path: "/returnMoneyOrGoods",
          query: { code: 0, itemId: this.data.orderItemId, applyType: 2, saleId: this.data.id, shopType: '0' },
        });
      }
    },
    onClickLeft() {
      let u = navigator.userAgent;
      let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //android
      let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) //ios终端
      if (isIOS) {
        window.webkit.messageHandlers.close.postMessage("up")
      }
      if (isAndroid) {
        window.android.close()
      }
    },

    flMallOrderRefund() {
      let u = navigator.userAgent;
      let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //android
      let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) //ios终端
      if (isIOS) {
        window.webkit.messageHandlers.flMallOrderRefund.postMessage('')
      }
      if (isAndroid) {
        window.android.flMallOrderRefund()
      }
    },

    getPage() {
      refundDetail({ saleId: this.$route.query.id }).then(res => {
        if (res.data.code === 0) {
          this.data = res.data.data
          this.icon = res.data.data.icon
          if (this.data.status == '6' && res.data.data.countDown) {
            this.getRefundTime(res.data.data.countDown)
          } else if (this.data.status == '1') {
            let time = res.data.data.createTime.replace(/-/g, '/')
            this.timer1 = setInterval(() => {
              this.endTime(time)
            }, 1000)
          }
        }
      })
    },
    //未发货退款
    endTime(createTime) {
      const time = new Date(createTime).getTime()
      // 一天后结束
      const endTime = time + 24 * 60 * 60 * 1000
      // 距离当前时间剩余多少秒
      let diff = (endTime - (new Date().getTime())) / 1000
      if (diff > 0) {
        const h = parseInt(diff / 3600)
        diff = diff - h * 3600
        const m = parseInt(diff / 60)
        diff = parseInt(diff - m * 60)
        this.residueTime = `${h}时${m}分${diff}秒`
      } else {
        clearInterval(this.timer1)
        this.getPage()
      }
    },
    //已发货仅退款
    getRefundTime(time) {
      // let timer = null
      // console.log(time, 'time');
      this.timeNum = time
      if (this.timeNum > 0) {
        this.timer1 = setInterval(() => {
          if (this.timeNum > 0) {
            this.timeNum = this.timeNum - 1
            let hh = Math.floor(Number(this.timeNum) / 60 / 60) >= 10 ? Math.floor(Number(this.timeNum) / 60 / 60) : '0' + Math.floor(Number(this.timeNum) / 60 / 60)
            let mm = Math.floor(Number(this.timeNum) / 60 % 60) >= 10 ? Math.floor(Number(this.timeNum) / 60 % 60) : '0' + Math.floor(Number(this.timeNum) / 60 % 60)
            let ss = Math.floor(Number(this.timeNum) % 60) >= 10 ? Math.floor(Number(this.timeNum) % 60) : '0' + Math.floor(Number(this.timeNum) % 60)
            this.residueTime = `${hh}时${mm}分${ss}秒`
          } else {
            clearInterval(this.timer1)
            this.getPage()
          }
          // console.log(this.residueTime, '???');
        }, 1000)
      } else {
        clearInterval(this.timer1)
      }
    },
    //撤销申请
    cancalRefundDetail() {
      Dialog.confirm({
        title: '提示',
        message: '确认撤销申请',
      }).then(() => {
        this.loading = true
        const data = {
          saleId: this.$route.query.id,
          types: '0'
        }
        if (this.data.status == '1') {
          cancalRefundDetail(data).then(res => {
            if (res.data.code === 0) {
              this.loading = false
              Toast('撤销成功')
              this.flMallOrderRefund()
            } else {
              Toast(res.data.message)
              this.loading = false
            }
          }).catch(() => {
            this.loading = false
            Toast('撤销失败，请联系客服')
          })

        } else if (this.data.status == '6') {
          giveUpApply(data).then(res => {
            if (res.data.code == 0) {
              this.loading = false
              Toast('撤销成功')
              this.flMallOrderRefund()
            } else {
              this.loading = false
              Toast(res.data.message)
            }
          }).catch(() => {
            this.loading = false
            Toast('撤销失败，请联系客服')
          })
        }
      }).catch(() => {
      })
    },

    selectReason(i) {
      this.index = i
    },
    //重新申请
    chexiaoshenqing() {
      this.loading = true
      const data = {
        orderId: this.data.orderId,
        refundReson: this.refundReason[this.index],
        itemId: this.data.orderItemId,
        saleId: this.data.id,
        types: 0,
      }
      chexiaoshenqing(data).then(res => {
        if (res.data.code === 0) {
          this.loading = false
          Toast('重新申请成功')
          this.show = false
          this.flMallOrderRefund()
          let u = navigator.userAgent;
          let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //android
          let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
          if (isIOS) {
            window.webkit.messageHandlers.flMallOrderRefund.postMessage("");
          }
          if (isAndroid) {
            window.android.flMallOrderRefund();
          }
        } else {
          this.loading = false
          Toast(res.data.message)
        }
      })
    },

    contact() {
      contact(this.icon)
    },

    callPhone() {
      console.log(88)
      let u = navigator.userAgent;
      let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //android
      let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      if (isIOS) {
        this.$refs.tels.href = 'tel://15365526315'
        this.$refs.tels.click()
      }
      if (isAndroid) {
        window.android.callPhone('15365526315');
      }
    },

    closeLoading () {
      this.loading = false
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .van-nav-bar__left {
  padding: 0 5px;
}

.container {
  background: #fff;
  margin-top: 0.4054rem;
}

.refuseReason {
  color: #333333;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.steps {
  &-item {
    font-size: 0.3784rem;
    color: #333;

    &-tow {
      font-size: 0.3243rem;
      color: #999;
    }
  }

  &-shuoming {
    font-size: 0.3784rem;
    color: #333;
  }
}

.info {
  padding: 0.4054rem;

  &-shangpin {
    display: flex;

    &-right {
      padding: 0.1351rem 0;
      margin-left: 0.2703rem;
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      &-name {
        display: flex;
        justify-content: space-between;

        &-ellipsis {
          margin-right: 10.0011px;
          overflow: hidden;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
        }
      }

      .shangpin_name {
        font-size: 14px;
        color: #333;
      }

      .shangpin_guige {
        font-size: 0.3243rem;
        color: #999;
      }
    }
  }

  &-btn {
    display: flex;
    justify-content: space-evenly;

    &-container {
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #ebedf0;
      border-radius: 999px;
      font-size: 0.3514rem;
      padding: 0.1351rem 0.4054rem;

      &-img {
        width: 0.5405rem;
        margin-right: 0.0811rem;
      }
    }

    &-kong {
      width: 107px;
      height: 32px;
    }

    .a_html {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #333333;
    }

    &-container:active {
      background: #e6e6e6;
    }
  }
}

.list {
  li {
    font-size: 0.3784rem;
    margin-bottom: 0.2703rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .hongse {
    color: #FB2B53;
  }
}

.shexiao {
  .van-tabbar {
    background: #fff;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0.4054rem 0.5405rem;
    border-top: 1px solid #E5E5E5;
    box-sizing: border-box;
  }

  .van-button--small {
    // padding: 0 0.4054rem;
    width: 2.5946rem;
    height: 0.8649rem;
    // margin-right: 0.6919rem;
    border: 1px solid #999999;
    background: #fff;
    border-radius: 0.4324rem;
  }
}

.box {
  height: 100%;
  color: #333;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0.2703rem 0;
  box-sizing: border-box;

  &-title {
    font-size: 0.4865rem;
    text-align: center;
  }

  &-item {
    font-size: 0.3784rem;
    padding: 0.2703rem 0.5405rem;
    border-bottom: 1px solid #F2F0F0;
    display: flex;
    align-items: center;
  }

  &-btn {
    padding: 0 0.2703rem;
  }

  .van-button {
    height: 1.0811rem;
  }
}
</style>